import gp2 from '../images/gp2.0.png'
import opera from '../images/opera-cultura.png'
import windmill from '../images/windmill2.0.png'
import bingo from '../images/bingo2.0.png'
import dnd from '../images/dnd.png'
import personal from '../images/personal.png'
import norman from '../images/norman.png'

export const projectInfo = [
    {
        "link": "/project/dnd",
        "type": "Web App",
        "title": "Role Playing Buddy",
        "description": "A web application for assisting in role playing campaigns",
        "credentials": true,
        "websiteURL": "https://classy-kulfi-77ddfa.netlify.app/login",
        "githubURL": "https://github.com/Matt-LaRochelle/DnD",
        "image": dnd,
        "techStack": {
            "frontend": true,
            "backend": true,
            "utilities": true
        }
},
{
    "link": "/project/norman",
    "type": "Website",
    "title": "Norman Espinoza",
    "description": "A website for an opera singer",
    "credentials": false,
    "websiteURL": "https://normanespinoza.com",
    "githubURL": "https://github.com/Matt-LaRochelle/norman-sings",
    "image": norman,
    "techStack": {
        "frontend": true,
        "backend": false,
        "utilities": true
    }
},
    {
        "link": "/project/opera",
        "type": "Website",
        "title": "Ópera Cultura",
        "description": "A website for a latino/x/a/é opera company and community school",
        "credentials": false,
        "websiteURL": "https://operacultura.org/",
        "githubURL": "",
        "image": opera,
        "techStack": {
            "frontend": true,
            "backend": true,
            "utilities": true
        }
},
    {
        "link": "/project/windmill",
        "type": "Website",
        "title": "Windmill Equestrian",
        "description": "A website for a local equestrian business",
        "credentials": false,
        "websiteURL": "https://gregarious-gumption-b9353e.netlify.app/",
        "githubURL": 'https://github.com/Matt-LaRochelle/horses',
        "image": windmill,
        "techStack": {
            "frontend": true,
            "backend": false,
            "utilities": true
        }
},
//     {
//         "link": "/project/gp2",
//         "type": "Web App",
//         "title": "Guitar Paths",
//         "description": "A web application designed for guitarists learning ear training",
//         "credentials": true,
//         "websiteURL": "https://guitar-paths.onrender.com/",
//         "githubURL": "https://github.com/Matt-LaRochelle/gp2",
//         "image": gp2,
//         "techStack": {
//             "frontend": true,
//             "backend": true,
//             "utilities": true
//         }
// },
    {
        "link": "/project/bingo",
        "type": "Web App",
        "title": "Bingo Cards",
        "description": "A web application designed for creating and printing bingo cards",
        "credentials": true,
        "websiteURL": "https://bingo-kncb.onrender.com/",
        "githubURL": "https://github.com/Matt-LaRochelle/bingo",
        "image": bingo,
        "techStack": {
            "frontend": true,
            "backend": true,
            "utilities": true
        }
},
    {
        "link": "/project/personal",
        "type": "Website",
        "title": "Personal Portfolio",
        "description": "My personal website where I showcase my work and skills",
        "credentials": false,
        "websiteURL": "https://mattsdevprojects.com/",
        "githubURL": "https://github.com/Matt-LaRochelle/matt-personal",
        "image": personal,
        "techStack": {
            "frontend": true,
            "backend": false,
            "utilities": true
        }
},
]